import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Concepts from './components/Concepts';
import Stories from './components/Stories';
import CardDetails from './components/CardDetails';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import TopmateEmbed from './components/TopmateEmbed';
import FloatingButton from './components/TopmateFloatingButton';

const App = () => {
  const [showButton, setShowButton] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Check if the current path is '/schedule-meet'
    setShowButton(location.pathname !== '/schedule-meet');
  }, [location.pathname]);

  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Concepts" element={<Concepts />} />
        <Route path="/Stories" element={<Stories />} />
        <Route path="/CardDetails/:id" element={<CardDetails />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/schedule-meet" element={<TopmateEmbed />} />
      </Routes>
      {showButton && <FloatingButton />} {/* Conditionally render FloatingButton */}
      <Footer />
    </div>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
