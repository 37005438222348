// Spinner.js
import React from 'react';
import { ClipLoader } from 'react-spinners';
import './Spinner.css'; // Import CSS for spinner styling

const Spinner = () => {
  return (
    <div className="spinner">
      <ClipLoader color="#110080" size={50} />
    </div>
  );
};

export default Spinner;
