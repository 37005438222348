// CardList.js
import React from 'react';
import { Link } from 'react-router-dom';
import "./ConceptCard.css";

import Story2 from "../images/Story2.png";
import Story1 from "../images/Story1.png";
import Story3 from "../images/Story3.png";
import Story4 from "../images/Story4.png";



const cardsData = [
 
{ id: 1, title: '4X Revenue Growth with Target Profitability for a Home & Kitchen brand', 
description: 'Home & Kitchen is one of the largest and fastest-growing categories in the Indian E-commerce landscape...', 
image: Story2 
},
  { id: 2, 
    title: 'Doubled the revenues of a Laptop Bags and Travel Accessories brand', 
    description: 'Our client here was at an INR 12 Cr INR (Annual Run Rate revenues) with product lines in Laptop Bags, Sleeves and Travel Duffles categories...', 
    image: Story3 
  },
  { id: 3, 
    title: 'Fundraise and Growth for Fresh Fruits D2C Brand (featured on Shark Tank)', 
    description: 'Healthy Snacking is a fast-growing category in the Indian E-commerce market. While most brands are into packaged offerings, our...', 
    image: Story1 
  },
  { id: 4, 
    title: 'Growth Strategy for a Sustainable Women’s Clothing Brand', 
    description: 'We conducted extensive benchmarking of similar brands and key factors that led to their success. Identified and established account connects with target marketplaces...', 
    image: Story4 
  },
  // Add more card data with images as needed
];

function CardList() {
  return (
    <div className= "HCards">
      {cardsData.map((card) => (
       
          <div className="horizontal-card">
          <div className="card-image">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-content">
              <h2>{card.title}</h2>
              <p>{card.description}</p>
              <Link to={`/CardDetails/${card.id}`} key={card.id} className="card-link"><button>Read More</button> </Link>
            </div>
          </div>
       
      ))}
    </div>
  );
}

export default CardList;
