import React from 'react';
import './Privacy.css'; // Import your CSS file for styling

function Privacy() {
  return (
    <div className='PrivacyCont'>
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-heading">Privacy Policy</h1>

      <p className="privacy-policy-text">
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit our website.
      </p>

      <h2 className="section-heading">Information We Collect</h2>
      <p className="privacy-policy-text">
        We may collect personal information that you provide directly to us
        through forms on our website. This information may include your name,
        email address, and other contact information.
      </p>

      <h2 className="section-heading">How We Use Your Information</h2>
      <p className="privacy-policy-text">
        We use the information we collect for various purposes, including to:
      </p>
      <ul className="privacy-policy-list">
        <li>Provide, maintain, and improve our website and services.</li>
        <li>Contact you for customer support and respond to your inquiries.</li>
        <li>Send you updates, newsletters, and promotional materials.</li>
      </ul>

      <h2 className="section-heading">Sharing Your Information</h2>
      <p className="privacy-policy-text">
        We may share your personal information with third-party service
        providers who help us operate our website and provide services. For
        example, we use a third-party email service to send newsletters.
      </p>

      <h2 className="section-heading">Cookies</h2>
      <p className="privacy-policy-text">
        Our website uses cookies to collect information about your browsing
        behavior and preferences. You can manage your cookie preferences
        through your browser settings.
      </p>

      <h2 className="section-heading">Security</h2>
      <p className="privacy-policy-text">
        We take measures to protect your personal information, but no data
        transmission or storage can be guaranteed to be 100% secure.
      </p>

      <h2 className="section-heading">Changes to This Privacy Policy</h2>
      <p className="privacy-policy-text">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>

      <h2 className="section-heading">Contact Us</h2>
      <p className="privacy-policy-text">
        If you have any questions or concerns about our Privacy Policy, please
        contact us.
      </p>
    </div>
    </div>
  );
}

export default Privacy;
