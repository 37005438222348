import React from 'react';
import './Terms.css'; // Import your CSS file for styling

function TermsOfUse() {
  return (
    <div className='TermsCont'>
    <div className="terms-of-use-container">
      <h1 className="terms-of-use-heading">Terms of Use</h1>

      <p className="terms-of-use-text">
        By using this website, you agree to comply with and be bound by the
        following terms and conditions of use. If you do not agree to these
        terms, please do not use our website.
      </p>

      <h2 className="section-heading">Use of the Website</h2>
      <p className="terms-of-use-text">
        This website is for general information and use only. It is subject to
        change without notice.
      </p>

      <h2 className="section-heading">User Responsibilities</h2>
      <p className="terms-of-use-text">
        You are responsible for your use of the website. You must ensure that
        your use of the website complies with all applicable laws and
        regulations.
      </p>

      <h2 className="section-heading">Privacy</h2>
      <p className="terms-of-use-text">
        Your use of this website is governed by our Privacy Policy.
      </p>

      <h2 className="section-heading">Intellectual Property</h2>
      <p className="terms-of-use-text">
        All trademarks, logos, and content displayed on the website are the
        property of their respective owners.
      </p>

      <h2 className="section-heading">Limitation of Liability</h2>
      <p className="terms-of-use-text">
        We are not liable for any indirect, incidental, or consequential
        damages that may arise from your use of this website.
      </p>

      <h2 className="section-heading">Changes to Terms</h2>
      <p className="terms-of-use-text">
        We may update these terms from time to time. Your continued use of the
        website after such changes will signify your acceptance of the modified
        terms.
      </p>
    </div>
    </div>
  );
}

export default TermsOfUse;
