import React, { useState } from 'react';
import './HorizontalCards.css'; // Import the CSS file for global styling
import Blog1 from '../images/Blog1.png';
import Blog2 from '../images/Blog2.png';
import Blog3 from '../images/Blog3.png';
import Blog4 from '../images/Blog4.png';
import Blog6 from '../images/Blog6.png';
import Blog5 from '../images/Blog5.png';



function App() {
  const initialCardsToShow = 4;
  const cardsPerPage = 4;

  const [cardsData, setCardsData] = useState([
    {
      image: Blog6,
      title: 'MoQs — the sourcing nightmare.',
      description: 'How to address the challenge of Minimum Order Quantity (MoQ), a determining factor in the initial investment required for launching D2c, often becomes a deciding factor ...',
      url: 'https://medium.com/@kdventures/moqs-the-sourcing-nightmare-e7df0e25df83', // External URL
      tags : ['#Sourcing', '#D2C', '#Procurement',]
    },
   
    {
      image: Blog3,
      title: 'Proven hacks in setting up high ownership culture',
      description: 'Setting up a culture that cultivates a high sense of ownership among team members is a key success factor in any startup growth journey. We have outlined here some of the hacks and best practices in this context..',
      url: 'https://medium.com/@kdventures/proven-hacks-in-setting-up-high-ownership-culture-27705476fa64', // External URL
      tags : ['#Leadership', '#Recruitment', '#Hiring']
    },

    {
      image: Blog4,
      title: 'What could really make or break your brand?',
      description: 'Obsessing over finer details can make or break your brand. Since Shark Tank, a lot of D2C Founders have reached out to me to build their brand. They ask me for quick fixes that will...',
      url: 'https://medium.com/@kdventures/what-could-make-or-break-your-brand-really-27ca1d07294a', // External URL
      tags : ['#Design', '#GoToMarket', '#Branding',]

    },
    
    {
      image: Blog2,
      title: 'How to choose a winning sourcing partner?',
      description: 'Key considerations for choosing the ideal sourcing partner, a pivotal decision in any (D2C) brand or product launch....',
      url: 'https://medium.com/@kdventures/how-to-choose-a-winning-sourcing-partner-d502f6f67f7a', // External URL
      tags : ['#Sourcing', '#D2C', '#Procurement']

    
    },
    {
      image: Blog5,
      title: 'High level of ownership and Founder’s mindset',
      description: 'How to identify Startups with high ownership culture? Here are two things I look out for: No one says “it’s NOT my job” at this place everyone is a founder..',
      url: 'https://medium.com/@kdventures/high-level-of-ownership-and-founders-mindset-b0e33b9acb46', // External URL
      tags : ['#TeamBuilding', '#HR', '#Leadership']

    },
    {
      image: Blog1,
      title: 'Change is the only constant',
      description: 'The phrase has been used for decades if not centuries and remains the catchphrase when experts, critics, veterans, etc. fail to flag the check post. …',
      url: 'https://medium.com/@kdventures/change-is-the-only-constant-763ae65b2ea5', // External URL
      tags : ['#Hustle', '#Covid', '#Innovation']

    }, 
    
    
    
    
  ]);

  const [cardsToShow, setCardsToShow] = useState(initialCardsToShow);

  const handleNextPage = () => {
    setCardsToShow(cardsToShow + cardsPerPage);
  };

  const handleShowLess = () => {
    setCardsToShow(cardsToShow - cardsPerPage);
  };

  return (
    <div className='ConceptsCont'>
      <div className="HCards">
        {cardsData.slice(0, cardsToShow).map((card, index) => (
          <div className="horizontal-card" key={index}>
            <div className="card-image">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-content">
            <div className="title">
                <h2>{card.title}</h2>
              </div>
              <div className="description">
                <p className='description-text'>{card.description}</p>
              </div>
             
              <div className="read-more-tags">
              <div className="tags-container">
                
                {card.tags && Array.isArray(card.tags) && card.tags.map((tag, tagIndex) => (
                  <div className='tagsDiv'>
                <span key={tagIndex} className="tags">{tag}</span>
                </div>
                ))}

                </div>
                <div className="read-more">
                  <a href={card.url} rel="noopener noreferrer">
                    <button>Read More</button>
                  </a>
                </div>
                
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='More'>
        {cardsToShow > initialCardsToShow && (
          <div className="pagination">
            <button className='PaginationBtn' onClick={handleShowLess}>Show Less</button>
          </div>
        )}
        {cardsToShow < cardsData.length && (
          <div className="pagination">
            <button className='PaginationBtn' onClick={handleNextPage}>Show More</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
