import React from 'react';
import './AboutUs.css';
import Service from './Service';
import TeamDetails from './TeamDetails';
import Operations from './Operations';
import Finance from './Finance';
import Growth from './Growth';


function AboutUs() {
  return (
    <div>
    <div className="about-us-container">
      <div className="about-us-banner">
        <h1>Who Are We?</h1>
        <h3>Team of <span>Experts</span> for<span> Go To Market </span> and <span>Growth </span> strategies</h3>
      </div>
      <div className="card-container">
        {/* Card 1 */}
        <div className="card">
          <h3>Growth Consultants</h3>
          <p>
          We are a team of problem solvers, growth consultants and angel investors
          </p>
        </div>
        {/* Card 2 */}
        <div className="middle-card">
          <h3>Enablers</h3>
          <p>
          We work hand in hand with early-stage founders to accelerate their journeys
          </p>
        </div>
        {/* Card 3 */}
        <div className="card">
          <h3>Industry Experts</h3>
          <p>
          We bring experience from the corporate sector, industry verticals and global markets 
          </p>
        </div>
      </div>
    </div>

    <div className="Exist-container">
      <h2>| Whom Do We Exist For?</h2>
      <div className="Ecard-container">
        {/* Card 1 */}
        <div className="Ecard">
          <h3>For Founders</h3>
          <p>
          We bring knowledge and learnings from similar founders and startups. We also match you with the most suitable mentor(s) and integrate you into a community for exchanging ideas and seeking support from other founders.            
          </p>
        </div>
        {/* Card 2 */}
        <div className="Ecard">
          <h3>For Corporates</h3>
          <p>
          We help corporates accelerate growth within and outside the employer organisation. We help you develop a Founder’s Mindset to enjoy entrepreneurial thrill within your corporate career.
          </p>
        </div>
        {/* Card 3 */}
        <div className="Ecard">
        <div className='EcardTitle'>
          <h3>For Students</h3>
          </div>
          <p>
          Habits, beliefs and connections built in academic years are the key pillars for personal and professional success. A significant part of our mission is dedicated to grooming the next generations of leaders from schools and universities.
          </p>
        </div>
      </div>
    </div>

    {/* <div className='Cont2'>
    <h2>| Why do we exist?</h2>
    <h3>
    While the startup world is all about the “Do It Yourself” paradigm, there is significant value that a strong network, community and mentor ecosystem brings. Partly the reason why there are distinct startup clusters that can be either geographically or virtually marked.
    </h3>
        
    </div> */}
    <div className='Features'>
    <div className="Features-container">
      <div className="Features-banner">
        <h2>| What We Do?</h2>
        <h3><span>Strategy</span> and <span>Implementation</span> support for <span>CXOs</span> in Indian Market</h3>
      </div>
      <div className="Fcard-container">
        {/* Card 1 */}
        <div className="Fcard">
        <div className='Num'>
        <h1>1.</h1>
        </div>
          <div>
          <h3>We accumulate and synthesise the best practices across successful startups</h3>
          </div>
        </div>
        {/* Card 2 */}
        <div className="Fcard">
        <h1>2.</h1>
          <h3>Organize regular events/knowledge-sharing sessions</h3>
          
        </div>
        {/* Card 3 */}
        <div className="Fcard">
        <h1>3.</h1>
          <h3>Connect founders with the best individuals to address a specific ambition or problem </h3>
          
        </div>
      </div>
    </div>

    </div>


    <div className='ServiceContainer'>
    <Service/>

    </div>
    <div className='Team'>
    <div className='TeamHeading'>
      <h2>| Meet Our Team Of Experts</h2>
      <div className='THeading'>
       <h3>Technology</h3>
       <hr className='TUnder'></hr>
       </div>
    </div>
    
      <TeamDetails/>
    </div>
    <div className='Team'>
    <div className='TeamHeading'>
      <div className='THeading'>
       <h3>Supply Chain</h3>
       <hr className='TUnder'></hr>
       </div>
    </div>
    
      <Operations/>
    </div>
    <div className='Team'>
    <div className='TeamHeading'>
      <div className='THeading'>
       <h3>Finance</h3>
       <hr className='TUnder'></hr>
       </div>
    </div>
    
      <Finance/>
    </div>

    <div className='Team'>
    <div className='TeamHeading'>
      <div className='THeading'>
       <h3>Growth Strategy</h3>
       <hr className='TUnder'></hr>
       </div>
    </div>
    
      <Growth/>
    </div>

    
    
    </div>
  );
}

export default AboutUs;
