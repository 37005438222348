import React, { useState } from 'react';
import './HorizontalCards.css'; // Import the CSS file for global styling
import SteveJobs from '../images/SteveJobs.png';
import "./Media.css";
import Media1 from '../images/Media1.png';
import Media2 from '../images/Media2.png';
import Media3 from '../images/Media3.png';
import Media4 from '../images/Media4.png';
import Media5 from '../images/Media5.png';





function Media() {
  const initialCardsToShow = 4;
  const cardsPerPage = 4;

  const [cardsData, setCardsData] = useState([

     
    {
      image: Media4,
      title: 'Promoting Sustainable Logistics & Social Responsibility',
      description: 'Welcome to this CSR interview with Ms. Zaiba Sarang, the co-founder of iThink Logistics, a technology-driven logistics company with a noble vision of making shipping...',
      url: 'https://indiacsr.in/promoting-sustainable-logistics-and-social-responsibility-an-interview-with-zaiba-sarang-co-founder-of-ithink-logistics/', // External URL for Card 1
    },
  
    
    {
      image: Media1,
      title: 'Sariska ranks #1 Product of the Day on Product Hunt',
      description: 'We love async work, yet sometimes, there’s nothing better than a live jamming session. But how do you make sure you are collaborating efficiently and not stuck in a shouting match of  scroll up, up… no, down!"?...',
      url: 'https://www.linkedin.com/pulse/lets-browse-together-producthunt/?utm_source=share&utm_medium=member_ios&utm_campaign=share_via', // External URL for Card 1
    },
    {
      image: Media2,
      title: 'Upscalio Acquires Gizga, Tizum, AirCase, and HomePuff',
      description: 'In a stellar 4-in-1 win, Upscalio, a roll-up e-commerce company that invests in and grows online-first brands, has picked up a stake in Gizga, Tizum, Aircase, and HomePuff brands....',
      url: 'https://www.indianretailer.com/news/upscalio-acquires-gizga-tizum-aircase-and-homepuff.n12828', // External URL for Card 2
    },
    {
      image: Media3,
      title: 'Planning to eat healthily',
      description: 'It has been two weeks into 2020. While many of us would have made new year resolutions to eat healthier and follow a nutritional diet, very few of us would have actually been able to follow it diligently....',
      url: 'https://yourstory.com/2020/01/gurugram-startup-falhari-healthy-food-fruits', // External URL for Card 2
    },
    {
      image: Media5,
      title: 'Introducing Co-Browsing, the Revolutionary Way to Empower Users',
      description: 'Imagine what you could provide your customers with virtual guidance as they navigate....',
      url: 'https://www.aninews.in/news/business/business/product-hunts-product-of-the-day-introducing-co-browsing-the-revolutionary-way-to-empower-users20230717152446/', // External URL for Card 2
    },
    // Add more cards with external URLs as needed
  ]);

  const [cardsToShow, setCardsToShow] = useState(initialCardsToShow);

  const handleNextPage = () => {
    setCardsToShow(cardsToShow + cardsPerPage);
  };

  const handleShowLess = () => {
    setCardsToShow(cardsToShow - cardsPerPage);
  };

  return (
    <div className='MediaCoverage'>
      <div className='BHead'>
        <h1>| Media Coverage</h1>
      </div>
      <div className="HCards">
        {cardsData.slice(0, cardsToShow).map((card, index) => (
          <div className="horizontal-card" key={index}>
            <div className="card-image">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-content">
              <h2>{card.title}</h2>
              <p>{card.description}</p>
              <a href={card.url} rel="noopener noreferrer">
                <button>Read More</button>
              </a>
            </div>
          </div>
        ))}
      </div>
      {cardsToShow > initialCardsToShow && (
        <div className="pagination">
          <button className='PaginationBtn' onClick={handleShowLess}>Show Less</button>
        </div>
      )}
      {cardsToShow < cardsData.length && (
        <div className="pagination">
          <button className='PaginationBtn' onClick={handleNextPage}>Show More</button>
        </div>
      )}
    </div>
  );
}

export default Media;
