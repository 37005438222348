import React, { useState } from 'react';
import './Team.css'; // Import the CSS file for global styling
import { FaLinkedin, FaTwitter } from 'react-icons/fa'; // Import icons from react-icons

import Finance1 from '../images/Finance1.png';
import Finance2 from '../images/Finance2.png';



function Finance() {
  const [teamData, setTeamData] = useState([
    {
      image: Finance1,
      name: 'Atul K Sharmaa',
      role: 'CEO',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/atul-k-sharmaa/',
      },
    },
    {
      image: Finance2,
      name: 'Mayur Choudhary',
      role: 'CTO',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/mayurchoudhary/  ',
      },
    },
    
 
  ]);

  return (
    <div className='TeamCardsContainer'>
      <div className="TeamCards">
        {teamData.map((member, index) => (
          <div className="team-card" key={index}>
            <div className="Tcard-image">
              <img className='TImg' src={member.image} alt={member.name} />
            </div>
            <div className="Tcard-content">
              <div className="name">
                <h2>{member.name}</h2>
              </div>
              <div className="Tsocial-handles">
                {Object.entries(member.socialHandles).map(([platform, link]) => (
                  <a 
                    key={platform}
                    href={link}
                    className={`Tsocial-icon ${platform}`}
                  >
                    {platform === 'linkedin' ? <FaLinkedin /> : null}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Finance;
