import React, { useState } from 'react';
import Spinner from './Spinner'; // Import the Spinner component
import './TopmateEmbed.css';
const TopmateEmbed = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className='TopmateDiv'>
      {isLoading && <Spinner />} {/* Show spinner while loading */}
      <iframe
        src="https://topmate.io/embed/profile/dhruvsoni?theme=D5534D"
        style={{
          border: 'none',
          width: '100%',
          height: '100%',
          backgroundColor: '#fff',
        }}
        title="Topmate Profile"
        onLoad={handleLoad} // Hide spinner when iframe is loaded
      />
    </div>
  );
};

export default TopmateEmbed;
