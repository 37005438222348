import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import './TopmateFloatingButton.css';
import { CiCalendar } from "react-icons/ci";



const FloatingButton = () => {
  

  return (
    <Link to='/schedule-meet'>
    <div className='floating-button'>
    <div><CiCalendar size={20}/></div>
    <div>
    <div className='floatingBtn'>
      Schedule a call
    </div>

    </div>
    </div>
    </Link>
  );
};

export default FloatingButton;
