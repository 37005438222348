import React from "react";
import "./Home.css";
import Landing from "../images/Landing.png";
import HorizontalCards from "./HorizontalCards";
import ClientCrousel from "./ClientCrousel";
import YtEmbed from "./YtEmbed";
import Media from "./Media";
import InstaReel from "./InstaReel";
import Footer from "./Footer";
import SharkTank from "../images/SharkTank.png";
import { Link } from "react-router-dom";

function Home() {
  return (
    <section id="home">
      <div className="container">
        <div className="LandingText">
          <h1>
            Your <span>partner</span> in <span>early-stage investments</span>{" "}
            and <span>growth</span>
          </h1>
          <div>
          {/* <button className="Talk">
            <a href="https://forms.gle/u7uB9kLpAn4FFqGF6">Let's Talk</a>
          </button> */}
        <Link to='/schedule-meet'>
          <button className="Topmate-button">
            Schedule a call
          </button>
        </Link>
          </div>
        </div>

        <div className="Landing">
          <img className="LandingImg" src={Landing} alt="KD Ventures Logo" />
        </div>
      </div>
      <div className="BlogCont">
        <div className="BHead">
          <h1>| Featured Concepts</h1>
        </div>
        <div>
          <HorizontalCards />
        </div>
      </div>
      <div className="ContComp">
        <ClientCrousel />
      </div>
      <div className="YtSharkTank">
        <div className="landing-container">
          {/* Left side (Tagline) */}
          <div className="left-side">
            <h1>
              <span className="Falhari">Falhari</span> <br></br>at Shark Tank
              India.
            </h1>
            <hr className="line"></hr>
            <img className="Sharktank" src={SharkTank} alt="Shark Tank Logo" />
          </div>

          {/* Right side (YouTube video embed) */}
          <div className="right-side">
            {/* Replace the YouTube video URL with your own */}

            <YtEmbed />
          </div>
        </div>
      </div>
      <div className="Media">
        <Media />
      </div>
      <div>
        {/* <div className='Insta'>
      <InstaReel/>
      </div> */}
      </div>
    </section>
  );
}

export default Home;
