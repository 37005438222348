import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Navbar.css";
import Logo from "../images/Logo.png";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const navLinks = document.querySelectorAll(".navbar-links li a");

    navLinks.forEach((navLink) => {
      const linkPath = navLink.getAttribute("href");
      if (linkPath === location.pathname) {
        navLink.classList.add("active-link");
      } else {
        navLink.classList.remove("active-link");
      }
    });
  }, [location.pathname]);

  const openGoogleForm = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSe-cLwgyF1UnLyZgpp8kfVeAzBa7nhCoMoS4qwnbrUIz26Fvw/viewform");
  };

  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <a className="" href="/">
        <div className="navbar-logo">
          <div>
            <img src={Logo} alt="KD Ventures Logo" />
          </div>
          <div>
            <h1>KD VENTURES</h1>
          </div>
        </div>
      </a>

      {/* Mobile Menu Icon */}
      <div className="hamburger-icon" onClick={toggleMobileMenu}>
        <div className={isMobileMenuOpen ? "hamburger open" : "hamburger"}></div>
      </div>

      {/* Navigation Links */}
      <ul
        className={
          isMobileMenuOpen ? "navbar-links mobile-menu" : "navbar-links"
        }
      >
        <li>
          <NavLink exact to="/" activeClassName="active-link" onClick={closeMobileMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/concepts" activeClassName="active-link" onClick={closeMobileMenu}>
            Concepts
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/Stories" activeClassName="active-link" onClick={closeMobileMenu}>
            Success Story
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/AboutUs" activeClassName="active-link" onClick={closeMobileMenu}>
            About Us
          </NavLink>
        </li>
        <li>
          <button className="Contact" onClick={openGoogleForm}>
            Contact Us
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
