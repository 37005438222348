import React from 'react';
import Slider from 'react-slick';
import './InstaReel.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Reel1 from '../videos/Reel1.mp4';

const reelData = [
  {
    id: 1,
    videoUrl: Reel1,
  },
  {
    id: 2,
    videoUrl: Reel1,
  },
  {
    id: 3,
    videoUrl: Reel1,
  },
  {
    id: 4,
    videoUrl: Reel1,
  },
  {
    id: 5,
    videoUrl: Reel1,
  },
  {
    id: 6,
    videoUrl: Reel1,
  },
  {
    id: 7,
    videoUrl: Reel1,
  },
  {
    id: 8,
    videoUrl: Reel1,
  },
  // Add more Reel video data objects as needed
];

function InstaReel() {
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '1',
    arrows: true, // Display arrows for navigation
   
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  // Calculate the margin for the slides
  const slideMargin = 20; // Adjust the value to set the desired gap width
  const slideWidth = `calc(calc(89% / ${settings.slidesToShow}) - ${slideMargin}px)`;

  // Add style to the Slider component to create the gap between slides
  const sliderStyle = {
    marginLeft: `-${slideMargin}px`,
  };

  return (
    <div className="instagram-reel-carousel">
      <Slider {...settings} style={sliderStyle}>
        {reelData.map((video) => (
          <div key={video.id} className="carousel-item" style={{ width: slideWidth }}>
            <video
              src={video.videoUrl}
              controls
              width="100%"
              height="auto"
              className="carousel-video"
            ></video>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default InstaReel;
