import React from "react";
import "./Concepts.css";
import HorizontalCards from "./HorizontalCards";
import ConceptsImg from "../images/ConceptsImg.png";
import ClientCrousel from "./ClientCrousel";
import { Link } from "react-router-dom";

function Concepts() {
  return (
    <section>
      <div className="Concept-container">
        <div className="LeftConcepts">
          <h1>
            Elevate your game with our{" "}
            <span>Tips, Insights and Strategies</span>{" "}
          </h1>
          {/* <button className="Talk">
            <a href="https://forms.gle/u7uB9kLpAn4FFqGF6">Let's Talk</a>
          </button> */}

          <Link to='/schedule-meet'>
          <button className="Topmate-button">
            Schedule a call
          </button>
        </Link>
        </div>
        <div className="rightConcepts">
          <img
            className="ConceptsBanner"
            src={ConceptsImg}
            alt="Concept Image"
          />
        </div>
      </div>

      <div className="CSidebar">
        <HorizontalCards />
      </div>
    </section>
  );
}

export default Concepts;
