import React from 'react';
import { FaCheckCircle, FaUser, FaBriefcase } from 'react-icons/fa';
import './Service.css'; // Create a CSS file for styling

const cardData = [
  {
    title: 'Launch Strategy',
    icon: <FaCheckCircle size={32} color='white' />,
    description: [
        'Category and Product Identification',
        'Marketplace onboarding',
        'Organization setup',
        'Listing Support',
        'Key Metrics & Reporting Dashboard'

      ],  },
  {
    title: 'Growth Strategy',
    icon: <FaCheckCircle size={32} color='white' />,
    description: [
        'NPD strategy sprints adn launch',
        'Core portfolio growth',
        'New channel expansion',
        'Marketing optimization',
        'Sale events support',
        'CEX and reviews'
      ],
  },
  {
    title: 'Fund Raise',
    icon: <FaCheckCircle size={32} color='white'/>,
    description: [
        'Market sizing',
        'Financial Projection',
        'Pitch deck Development',
        'Valuation',
        'Revenue based financing'
      ],  },
  {
    title: 'Finance & Accounting',
    icon: <FaCheckCircle size={32} color='white'/>,
    description: [
        'Financial Audit for EBITDA levers',
        'Cost optimization strategy',
        'Brand P&L and reporting setup',
      ],  },
  {
    title: 'Analytics & Automation',
    icon: <FaCheckCircle size={32} color='white' />,
    description: [
        'Daily sales and marketing reports automation',
        'Inventory management tracker',
        'Pricing Analytics',
        'Unit Ecconomics trackers',
      ],  },
  {
    title: 'D2C Website',
    icon: <FaCheckCircle size={32} color='white'/>,
    description:[ 
    'D2C website development',
    'D2C operations',
    'Branding & Design',
    'Campaign management',
    'SEO & Content',
    'Logistics',
    'Dashboard and reporting'
    ]
  },
  // Add more data objects as needed
];

function Service() {
  return (
    <div className='ServiceCards'>
    <h2>| What we Offer?</h2>
    <div className="vertical-cards">
    
      {cardData.map((card, index) => (
        <div className="Vcard" key={index}>
          <div className="Vcard-title">
            {card.icon}
            <h3>{card.title}</h3>
          </div>
          {Array.isArray(card.description) ? (
            <ul className="Vcard-description">
              {card.description.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
          ) : (
            <p className="Vcard-description">{card.description}</p>
          )}
        </div>
      ))}
    </div>
  
    </div>
  );
}

export default Service;
