import React, { useState } from 'react';
import './Team.css'; // Import the CSS file for global styling
import { FaLinkedin, FaTwitter } from 'react-icons/fa'; // Import icons from react-icons

import Operations1 from '../images/Operations1.png';
import Operations2 from '../images/Operations2.png';
import Operations3 from '../images/Operations3.png';



function Operations() {
  const [teamData, setTeamData] = useState([
    {
      image: Operations1,
      name: 'Zaiba Sarang',
      role: 'CEO',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/zaiba-sarang/',
      },
    },
    {
      image: Operations2,
      name: 'Pradnya Bagde',
      role: 'CTO',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/pradnya-bagde-b9a91a25/     ',
      },
    },
    {
      image: Operations3,
      name: 'Rahul Bhardwaj',
      role: 'CTO',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/rahul-shankar-bhardwaj-9830851a/    ',
      },
    },
 
  ]);

  return (
    <div className='TeamCardsContainer'>
      <div className="TeamCards">
        {teamData.map((member, index) => (
          <div className="team-card" key={index}>
            <div className="Tcard-image">
              <img className='TImg' src={member.image} alt={member.name} />
            </div>
            <div className="Tcard-content">
              <div className="name">
                <h2>{member.name}</h2>
              </div>
              <div className="Tsocial-handles">
                {Object.entries(member.socialHandles).map(([platform, link]) => (
                  <a 
                    key={platform}
                    href={link}
                    className={`Tsocial-icon ${platform}`}
                  >
                    {platform === 'linkedin' ? <FaLinkedin /> : null}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Operations;
