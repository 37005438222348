import React, { useState } from 'react';
import './Team.css'; // Import the CSS file for global styling
import { FaLinkedin, FaTwitter } from 'react-icons/fa'; // Import icons from react-icons

import Growth1 from '../images/Growth1.png';
import Growth2 from '../images/Growth2.png';
import Growth3 from '../images/Growth3.png';



function Growth() {
  const [teamData, setTeamData] = useState([
    
    {
      image: Growth2,
      name: 'Pranika',
      role: '',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/pspranika/ ',
      },
    },
    {
      image: Growth3,
      name: 'Paarth Chadha',
      role: '',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/paarth-chadha-19b83b140/ ',
      },
    },
    {
      image: Growth1,
      name: 'Dhruv Soni',
      role: 'Founder',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/dhruvsonid2c/',
      },
    },
 
  ]);

  return (
    <div className='TeamCardsContainer'>
      <div className="TeamCards">
        {teamData.map((member, index) => (
          <div className="team-card" key={index}>
            <div className="Tcard-image">
              <img className='TImg' src={member.image} alt={member.name} />
            </div>
            <div className="Tcard-content">
              <div className="name">
                <h2>{member.name}</h2>
              </div>
              <div className="name">
                <p>{member.role}</p>
              </div>
              <div className="Tsocial-handles">
                {Object.entries(member.socialHandles).map(([platform, link]) => (
                  <a 
                    key={platform}
                    href={link}
                    className={`Tsocial-icon ${platform}`}
                  >
                    {platform === 'linkedin' ? <FaLinkedin /> : null}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Growth;
