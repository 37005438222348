import React from 'react';
import {  FaTwitter, FaLinkedin, FaInstagram, } from 'react-icons/fa'; // Import social icons
import { FaMedium } from "react-icons/fa6";
import './Footer.css'; // Import the CSS file for styling
import Logo from '../images/Logo.png';
import Privacy from './Privacy';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section">
          <img src={Logo} alt="Company Logo" className="company-logo" />
          <p className="tagline">KD VENTURES</p>
        </div>

        <div className="footer-section quick-links">
          <h3>Quick links</h3>
          <ul>
            <li><a href="./AboutUs">About</a></li>
            <li><a href="./Stories">Success Stories</a></li>
            <li><a href="Concepts">Concepts</a></li>
            <li><a href="https://forms.gle/u7uB9kLpAn4FFqGF6">Contact Us</a></li>

          </ul>
        </div>

        
        <div className="footer-section social-section">
          <h3>Follow Us</h3>
          <ul className="social-icons">
            <li><a href="https://twitter.com/dhruv_soni1912"><FaTwitter /></a></li>
            <li><a href="https://www.linkedin.com/in/dhruvsonid2c/"><FaLinkedin /></a></li>
            <li><a href="https://www.instagram.com/dhruv.at.insta/"><FaInstagram /></a></li>
            <li><a href="https://medium.com/@kdventures"><FaMedium /></a></li>
          </ul>
        </div>
      </div>
      <hr></hr>
      <div className='Privacy'>
        <h5>
          © KD Ventures 
        </h5>

        <a href="./Privacy"><h5 >| Privacy & Policy</h5></a>
        <a href="./Terms"><h5 >| Terms of Use</h5></a>
        
      </div>
      
    </footer>
  );
}

export default Footer;
