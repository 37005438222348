import React from 'react';
import './Story.css';
import Landing from '../images/Landing.png';
import SuccessCard from './SuccessCard';
import StoryBanner from '../images/StoryBanner.png';
import ClientCrousel from './ClientCrousel'
import { Link } from 'react-router-dom';

function Stories() {
  return (
    <section id="home">
      <div className="Scontainer">
      <div className='LandingText'>
        <h1>
        Success story that got <span>crafted</span> in <span>partnership</span> with us
        </h1>
        {/* <button className='Talk'>
  <a href="https://forms.gle/u7uB9kLpAn4FFqGF6" >
    Let's Talk
  </a>
</button> */}

      <Link to='/schedule-meet'>
          <button className="Topmate-button">
            Schedule a call
          </button>
        </Link>

            

      </div>
      
      <div className='Landing'>
        
        <img className='LandingImg' src={StoryBanner} alt="KD Ventures Logo" />
      </div>
      
      </div>
      
      <div>
        <SuccessCard/>
      </div>
      <div className='StoryClient'>
      <div className='ContComp'>
        <ClientCrousel/>
      </div>
      </div>
     
    </section>
  );
}

export default Stories;
