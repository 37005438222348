import React, { useState } from 'react';
import './Team.css'; // Import the CSS file for global styling
import { FaLinkedin } from 'react-icons/fa'; // Import the LinkedIn icon from react-icons

import Tech1 from '../images/Tech1.png';
import Tech2 from '../images/Tech2.png';

function TeamDetails() {
  const [teamData, setTeamData] = useState([
    {
      image: Tech1,
      name: 'Dushyant Sabharwal',
      role: 'CEO',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/dushyantsabharwal/',
      },
    },
    {
      image: Tech2,
      name: 'Gaurav Toshniwal',
      role: 'CTO',
      socialHandles: {
        linkedin: 'https://www.linkedin.com/in/gauravtoshniwal/',
      },
    },
 
  ]);

  return (
    <div className='TeamCardsContainer'>
      <div className="TeamCards">
        {teamData.map((member, index) => (
          <div className="team-card" key={index}>
            <div className="Tcard-image">
              <img className='TImg' src={member.image} alt={member.name} />
            </div>
            <div className="Tcard-content">
              <div className="name">
                <h2>{member.name}</h2>
              </div>
              <div className="Tsocial-handles">
                {Object.entries(member.socialHandles).map(([platform, link]) => (
                  <a 
                    key={platform}
                    href={link}
                    className={`Tsocial-icon ${platform}`}
                  >
                    {platform === 'linkedin' ? <FaLinkedin /> : null}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeamDetails;
