import React from 'react';
import { useParams } from 'react-router-dom';
import './CardDetails.css';

const cardsData = [
  {
    id: 1,
    title: '4X Revenue Growth with Target Profitability for a Home & Kitchen brand',
    description: [
      'Context',
      'Home & Kitchen is one of the largest and fastest-growing categories in the Indian E-commerce landscape. Our client here was at an INR 6 Cr INR ARR (Annual Run Rate revenues) with product lines in Lunch Boxes (steel, borosilicate) and Food Jars categories. While the brand had limited but successful product lines, it was facing downward pressure on revenues due to multiple reasons to be addressed and lacked an aggressive growth plan as well as its execution. Our target was to achieve 4X revenue growth within a 15% EBITDA margin guardrail over 1 year timeline. Also, the target was to formulate business operation processes for smooth operations, steady growth, and target a lean organization from a cost optimization perspective.', 
      
      'Impact Created', '- 4X revenue growth within 9 months', '- 30 new product line launches\n', '- D2C website launch and 15% new revenues\n', '- Entry to 8 new e-commerce + offline channels making the brand truly omnichannel','','', 'Detailed Success Story', ' 1. Branding revamp across packaging, listings, social media and other touch-points', '2. Mobile application revamp and subscription business launch', '3. Angel funding leveraging HNI/ NRI investors interested in Indian startups','','', 'Conceptualised a new brand identity and created new design elements across customer touch-points covering regular digital communications, packaging, website, feedback requests etc. Ensured maximum impact from design in terms of communicating fresh, healthy and premium propositions of the offerings leading to a direct impact on new customer acquisition and repeat purchases.', 'Simplified end-to-end customer journey, improved overall user experience and redesigned creative elements on the mobile application to improve customer acquisitions and order volumes from the mobile application. Conceptualised and launched the subscription business model leveraging upgraded mobile application with new revenues and loyal customers addition to the brand.', 'Structured both Equity and Debt funding options and led multiple rounds of funding at critical times of the startup journey. Ensured business continuity, target growth rate and expansion to new cities (cloud kitchens) by identifying Angel Investors and concluding funding rounds in an efficient manner.    '
    ],
  },

  {
    id: 2,
    title: 'Doubled the revenues of a Laptop Bags and Travel Accessories brand',
    description: [
      'Context',
      'Our client here was at an INR 12 Cr INR (Annual Run Rate revenues) with product lines in Laptop Bags, Sleeves and Travel Duffles categories. The brand already had a few best-seller products and was on a steady growth trajectory.Our target was to accelerate the revenue growth for the brand while maintaining the  target EBITDA margin guardrail over the next 1 year.ome & Kitchen is one of the largest and fastest-growing categories in the Indian E-commerce landscape. Our client here was at an INR 6 Cr INR ARR (Annual Run Rate revenues) with product lines in Lunch Boxes (steel, borosilicate) and Food Jars categories. While the brand had limited but successful product lines, it was facing downward pressure on revenues due to multiple reasons to be addressed and lacked an aggressive growth plan as well as its execution. Our target was to achieve 4X revenue growth within a 15% EBITDA margin guardrail over 1 year timeline. Also, the target was to formulate business operation processes for smooth operations, steady growth, and target a lean organization from a cost optimization perspective.', 
      
      'Impact Created', 
      '- 2X revenue growth over 1 year', '- 20 new product line launches\n', '- Entry to 8 new e-commerce + offline channels making the brand truly omnichannel \n', '- Global expansion (US, UK and UAE markets), 10% new revenues from global markets','','', 
      
      'Detailed Success Story', 
      '1. Aggressive approach to successful launches of New Product lines', '2. New revenues from channel expansions beyond Amazon (as-is main channel of sales)', '3. Global expansion (US, UK and UAE markets)', '4. Listing optimisation for better CVR/ CTR across channels', '5. Automation across operations and reporting', 
      
      'Identified fastest-growing categories and launched 20 new product lines to address market gaps. New product line launches covered multiple areas including expansion to new fabrics (such as Vegan Leather, Canvas etc.), launch of travel accessories adjacent to current travel duffles (e.g. toiletry kits, gym duffles, packing cubes). Defined super-efficient processes in new product launch-related tasks (sampling, vendor selection, photoshoots, listing, reviews, marketing etc.). Resulted in making best-sellers within 1 month of the launch of new products. Ran quick experiments in launch strategies across pricing, marketing, etc. areas, identified success factors, and replicated all new launches', 

      'We identified fast-growing and most relevant channels for the brand and listed the best sellers there to quickly capture new revenues. The brand transformed from an original 2 channel focus (Amazon , Flipkart) brand to a truly omnichannel brand and captured incremental revenues on existing and new product lines',
      
      'Identified product lines suitable for global markets and defined a demand plan and pricing approach for best market-entry. Identified and designed a local ecosystem in target markets for the successful launch across US, UK and UAE markets (logistics partner, warehousing and fulfilment approach, returns management etc.). Defined and implemented required processes for smooth global operations post-launch, including marketing approach and stock replenishment.',
      
      'Winning the Listing from the organic ranking perspective was at the centre of the target growth strategy.  From the inclusion of best-ranking Keywords across Titles and bullets to best-performing Product Images, Videos and A+ content, all aspects of listing were optimised to achieve organic success and capture the best performance from Marketing expenditure. ',

      'One of the success factors in ensuring the success of multiple parallel initiatives was to ensure business processes are defined and automated on priority. We worked closing with all business functions (from sourcing, fulfilment to marketing and finance) and defined all critical processes and resolved potential bottlenecks. Robust dashboards were defined for monitoring and course-correcting sales initiatives, marketing experiments and new launches across channels in a timely manner.'
    ],
  },

  {
    id: 3,
    title: 'Fundraise and Growth for Fresh Fruits D2C Brand (featured on Shark Tank)',
    description: [
      'Context',
      'Healthy Snacking is a fast-growing category in the Indian E-commerce market. While most brands are into packaged offerings, our client was one of the first and largest Fresh Fruit Products D2C brands with products ranging from salads, juices, smoothies and other healthy snacking options such as dips, seeds etc. The target was to define and implement levers for accelerated growth and also lead the required fundraise in achieving the same.', 
      
      'Impact Created', 
      '- 3 rounds of successful fundraise', '- 6X revenue growth within 1 year', '- D2C website launch to reduce dependency on marketplace channels', '- Achieved breakeven and profitability within 1 year','','', 
      
      'Detailed Success Story', 
      '1. Branding revamp (across packaging, listings, social media and other touch-points)', '2. Mobile application revamp and subscription business launch', '3. Angel funding leveraging HNI/ NRI investors interested in Indian startups', '', '', 
      
      'Conceptualised a new brand identity and created new design elements across customer touch-points covering regular digital communications, packaging, website, feedback requests etc. Ensured maximum impact from design in terms of communicating fresh, healthy and premium propositions of the offerings leading to a direct impact on new customer acquisition and repeat purchases.  ', 

      'Simplified end-to-end customer journey, improved overall user experience and redesigned creative elements on the mobile application to improve customer acquisitions and order volumes from the mobile application. Conceptualised and launched the subscription business model leveraging upgraded mobile application with new revenues and loyal customers addition to the brand.',
      
      'Structured both Equity and Debt funding options and led multiple rounds of funding at critical times of the startup journey. Ensured business continuity, target growth rate and expansion to new cities (cloud kitchens) by identifying Angel Investors and concluding funding rounds in an efficient manner.    ',
      
      ' ',

      ''
    ],
  },

  {
    id: 4,
    title: 'Growth Strategy for a Sustainable Women’s Clothing Brand',
    description: [
      'Context',
      'Tier 2 cities in India present a large and significantly fast-growing market for premium brands in the sustainable and designer segment clothing range. In particular Women’s Clothing category has seen significant development in recent years. Our client here family business led by a young dynamic entrepreneur focused on the offline first business model. ', 
      
      'Impact Created', 
      '- Expanded into online marketplaces such as Nykaa, Pernia’s Pop Up Shop etc.',
      '- Achieved target sales from a newly launched D2C Website by revamping its SEO and Marketing',
      '- Defined and helped in implementing an influencer marketing strategy','','','', 
      
      'Detailed Success Story', 
      '','','', '', '', 
      
      'We conducted extensive benchmarking of similar brands and key factors that led to their success. Identified and established account connects with target marketplaces for fast paced listing and launch across Nykaa, Pernia’s Pop Up Shop etc.  ', 

      'Assessed the current website for its performance and identified gaps to be fixed. Revamped the SEO to improve organic ranking across target keywords. Set up channel teams, and processes for efficient order processing across channels.  ',
      
      'Currently, we are working with the brand for multi-city offline launches, organising Pop-Up events and further improving production cycle timelines. ',
      
      ' ',

      ''
    ],
  },


  

  

  // Add more card data if needed
];

function CardDetails() {
  const { id } = useParams();
  const cardId = parseInt(id);
  const card = cardsData.find((c) => c.id === cardId);

  if (!card) {
    return <div>Card not found.</div>;
  }

  return (
    <div className="BlogCardDetails">
      <div className="card-detail">
        <h1>{card.title}</h1>
        <div className="description-text">
          <div className="boldtext"><h3>Context</h3></div>
          <div><p>{card.description[1]}</p></div>
          <div className="boldtext"><h3>Impact Created</h3></div>
          <div><p>{card.description[3]}</p></div>
          <div><p>{card.description[4]}</p></div>
          <div><p>{card.description[5]}</p></div>
          <div><p>{card.description[6]}</p></div>
          <div><p>{card.description[7]}</p></div>
          <div><p>{card.description[8]}</p></div>

          <div className="boldtext"><h3>Detailed Success Story</h3></div>
          <div><p>{card.description[10]}</p></div>
          <div><p>{card.description[11]}</p></div>
          <div><p>{card.description[12]}</p></div>
          <div><p>{card.description[13]}</p></div>
          <div><p>{card.description[14]}</p></div>

          <div className="boldtext"><h3>{card.description[10]}</h3></div>
          <div><p>{card.description[15]}</p></div>
          <div className="boldtext"><h3>{card.description[11]}</h3></div>
          <div><p>{card.description[16]}</p></div>
          <div className="boldtext"><h3>{card.description[12]}</h3></div>
          <div><p>{card.description[17]}</p></div>
          <div className="boldtext"><h3>{card.description[13]}</h3></div>
          <div><p>{card.description[18]}</p></div>
          <div className="boldtext"><h3>{card.description[14]}</h3></div>
          <div><p>{card.description[19]}</p></div>







        </div>
      </div>
    </div>
  );
}

export default CardDetails;
